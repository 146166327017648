export default [
  {
    path: '/events-manager/events',
    name: 'manage-events',
    component: () => import('@/views/event-management-system/EventManager/EventList.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Manage Events', active: true },
      ],
      pageTitle: 'Manage Events | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'Events Manager',
    },
  },
  {
    path: '/events-manager/events/:event_id',
    name: 'manage-events-specific',
    component: () => import('@/views/event-management-system/EventManager/EventList.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Manage Events', active: true },
      ],
      pageTitle: 'Manage Events | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'Events Manager',
    },
  },

  {
    path: '/events-manager/booking-slots',
    name: 'manage-booking-slots',
    component: () => import('@/views/event-management-system/EventManager/BookingSlots.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Manage Booking Slots', active: true },
      ],
      pageTitle: 'Manage Booking Slots | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
    },
  },
  {
    path: '/events-manager/preview/:event_id',
    name: 'event-preview',
    component: () => import('@/views/event-management-system/EventManager/EventPreview.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Event Preview', active: true },
      ],
      pageTitle: 'Event Preview | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'Events Manager',
    },
  },

  {
    path: '/events-manager/pending-jobs/:event_id',
    name: 'jobs-pending-approval',
    component: () => import('@/views/event-management-system/EventManager/PendingJobs.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Jobs Pending Approval', active: true },
      ],
      pageTitle: 'Jobs Pending Approval | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'Events Manager',
    },
  },

  {
    path: '/events-manager/edit-event',
    name: 'edit-events',
    component: () => import('@/views/event-management-system/EventManager/EditEvent.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Manage Events', active: true },
      ],
      pageTitle: 'Manage Events | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'Events Manager',
    },
  },

  {
    path: '/events-manager/edit-event/:event_id?',
    name: 'edit-event',
    component: () => import('@/views/event-management-system/EventManager/EditEvent.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Manage Events', active: true },
      ],
      pageTitle: 'Manage Events | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'Events Manager',
    },
  },

  {
    path: '/events-manager/interview-preparation',
    name: 'interview-prep',
    component: () => import('@/views/event-management-system/resources/Resource.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Resource', active: true },
      ],
      pageTitle: 'Resource | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
    },
  },

  {
    path: '/events-manager/applications/:event_id?',
    name: 'event-applications',
    component: () => import('@/views/event-management-system/EventManager/EventApplications.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Manage Events', active: true },
      ],
      pageTitle: 'Event Applications | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'Events Manager',
    },
  },

  {
    path: '/events-manager/applicant-profile/:booking_id?',
    name: 'applicant-info',
    component: () => import('@/views/event-management-system/EventManager/ApplicantInfo.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Manage Events', active: true },
      ],
      pageTitle: 'Applicant Info | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'Events Manager',
    },
  },

  {
    path: '/events-manager/participating-events/:event_id?',
    name: 'participating-jobs',
    component: () => import('@/views/event-management-system/EventManager/EventList.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Manage Events', active: true },
      ],
      pageTitle: 'Participating Jobs | BETA',
      rule: 'editor',
      group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: false,
      section: 'Events Manager',
    },
  },
  {
    path: '/events-manager/rooms',
    name: 'interview-room',
    component: () => import('@/views/event-management-system/InterviewRoom.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Interview Room', active: true },
      ],
      pageTitle: 'Interview Room | BETA',
      rule: 'editor',
      allowAnonymous: false,
    },
  },

  {
    path: '/events-manager/rooms/:booking_id?',
    name: 'specific-interview-room',

    component: () => import('@/views/event-management-system/InterviewRoom.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Interview Room', active: true },
      ],
      pageTitle: 'Interview Room | BETA',
      rule: 'editor',
      allowAnonymous: false,
      section: 'Events Manager',
    },
  },
  {
    path: '/events-manager/calendar',
    name: 'events-calendar',
    component: () => import('@/views/event-management-system/calendar/Calendar.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Calendar', active: true },
      ],
      pageTitle: 'Calendar | BETA',
      rule: 'editor',
      allowAnonymous: false,
    },
  },
  {
    path: '/active-events',
    name: 'active-events',

    component: () => import('@/views/event-management-system/InterviewRoom.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Active Events', active: true },
      ],
      pageTitle: 'Active Events | BETA',
      rule: 'editor',
      allowAnonymous: false,
    },
  },

  {
    // path: '/auth/register',
    path: '/interview/reschedule/:id',
    name: 'reschedule-interview',
    component: () => import('@/views/event-management-system/RescheduleInterview.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Reschedule Interview', active: true },
      ],
      pageTitle: 'Reschedule Interview | BETA',
      rule: 'editor',
      allowAnonymous: false,
    },
  },
  {
    path: '/events-list',
    name: 'view-jobs',
    component: () => import('@/views/event-management-system/Listings.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'View Jobs', active: true },
      ],
      pageTitle: 'View Jobs | BETA',
      rule: 'editor',
      group: ['applicant'],
      allowAnonymous: false,
    },
  },

  {
    path: '/my-applications',
    name: 'manage-applications',
    component: () => import('@/views/event-management-system/Applications.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Manage Applications', active: true },
      ],
      pageTitle: 'Manage Applications | BETA',
      rule: 'editor',
      group: ['applicant'],
      allowAnonymous: false,
    },
  },
  {
    path: '/training',
    name: 'training-video',
    component: () => import('@/views/event-management-system/Training.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Training Video', active: true },
      ],
      pageTitle: 'Training Video | BETA',
      rule: 'editor',
      group: ['applicant'],
      allowAnonymous: false,
    },
  },

  {
    path: '/events-manager/interview-preparation',
    name: 'interview-prep',
    component: () => import('@/views/event-management-system/resources/Resource.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Resource', active: true },
      ],
      pageTitle: 'Resource | BETA',
      rule: 'editor',
      group: ['applicant', 'agency', 'employer', 'education_institution'],
      allowAnonymous: false,
    },
  },

  {
    path: '/events-manager/resources',
    name: 'resources',
    component: () => import('@/views/event-management-system/resources/Resource.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Resource', active: true },
      ],
      pageTitle: 'Resource | BETA',
      rule: 'editor',
      group: ['applicant', 'agency', 'employer', 'education_institution'],
      allowAnonymous: false,
    },
  },

  {
    path: '/resources/user-guide',
    name: 'resources-user-guide',
    component: () => import('@/views/event-management-system/resources/UserGuide.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Resource | User Guide ', active: true },
      ],
      pageTitle: 'Resource | User Guide | BETA',
      rule: 'editor',
      group: ['applicant', 'agency', 'employer', 'education_institution'],
      allowAnonymous: false,
    },
  },

  {
    path: '/resources/training',
    name: 'resources-training',
    component: () => import('@/views/event-management-system/resources/Training.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Resource', active: true },
      ],
      pageTitle: 'Resource | BETA',
      rule: 'editor',
      group: ['applicant', 'agency', 'employer', 'education_institution'],
      allowAnonymous: false,
    },
  },

  {
    path: '/listings',
    name: 'listings-page',
    component: () => import('@/views/event-management-system/Listings.vue'),
    meta: {
      allowAnonymous: true,
      public: true,
      layout: 'full',
    },
  },

  {
    path: '/listings/:company?',
    name: 'company-listings-page',
    component: () => import('@/views/event-management-system/Listings.vue'),
    meta: {
      allowAnonymous: true,
      public: true,
      layout: 'full',
    },

  },
  {
    path: '/events/:eventid?',
    name: 'page-event',
    component: () => import('@/views/event-management-system/ListEvent.vue'),
    meta: {
      allowAnonymous: true,
      public: true,
      layout: 'full',
    },
  },

  {
    path: '/apply',
    name: 'apply-job',
    component: () => import('@/views/event-management-system/Apply.vue'),
    meta: {
      allowAnonymous: true,
      public: true,
    },
  },
  // {
  //   path: "/events-manager/insights",
  //   name: "Insights",

  //   component: () => import("./views/events/EventsInsights.vue"),
  //   meta: {
  //     breadcrumb: [
  //       { title: "Home", url: "/" },
  //       { title: "Insights", active: true },
  //     ],
  //     pageTitle: "Insights | BETA",
  //     rule: "editor",

  //     allowAnonymous: false,
  //   },
  // },
]
