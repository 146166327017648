/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { isUserLoggedIn } from '@/auth/utils'
import { orderBy } from 'lodash'
import {
  allIndustries,
  highestQualifications,
  genderOptions,
  employmentStatusOptions,
  citizenshipOptions,
  allAvailabilityOptions,
  prefWorkLocationOptions,
} from '@/constants/datastore'
import { singleUpload, deleteObjectByKey } from '@/components/awsS3'

// define a mixin object
export const listingsMixin = {
  data() {
    return {
      popup_event: {},
      popup_quiz: false,
      save_nric: false,
      popup_questions: [],
      isE2iLoaAccepted: false,
      isCompanyLoaAccepted: false,

      user: {
        availability: {},
        two_factor: false,
      },
      popupProfileQuestions: false,
      currentProfileQuestion: '', // which question to show, also to keep track next and previous button
      profileQuestionList: [], // store name of field needed to ask
      uploadText: 'Upload My Resume',
      uploadLoading: false,

      // profile completion modal
      normal: true,
      // options
      genderOptions: [],
      employmentStatusOptions: [],
      citizenshipOptions: [],
      allAvailabilityOptions: [],
      prefWorkLocationOptions: [],
      prefIndustryOptions: [],
      preferred_position: [],
      registered: [],

      employmentInfo: [],
      educationInfo: [],

      document_type: 'pdf',
      file_source: '',
      isLoading: false,
      resume_choice: 'existing-resume',
      resume_file: null,
      highestQualifications: [],

      applicationId: null, // send job_id to backend when resume is uploaded to keep track of resume_file
    }
  },
  created() {
    this.genderOptions = genderOptions
    this.employmentStatusOptions = employmentStatusOptions
    this.citizenshipOptions = citizenshipOptions
    this.allAvailabilityOptions = allAvailabilityOptions
    this.prefWorkLocationOptions = prefWorkLocationOptions
    // typeform
    this.prefIndustryOptions = orderBy(allIndustries, ['value'], ['asc'])
    this.highestQualifications = orderBy(highestQualifications, ['value'], ['asc'])
    // this.popupProfileQuestions = this.activatePopup
  },
  methods: {
    checkProfileCompletion() {
      let incomplete = true
      try {
        incomplete = this.$store.state.auth.ActiveUser.profile_status.incomplete
      } catch (error) {
        incomplete = true
      }
      return incomplete
    },
    handleResumePondInit() {
      const { id } = this.$store.state.auth.ActiveUser
      const outerThis = this
      this.$refs['pond-resume']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()

            singleUpload(file, 'user-resume', `${id}.${extension}`, load, error, progress, abort, null, outerThis)
          },
        },
      })
    },
    uploadResume(applicationID, job_id, application_url, user_id) {
      const reader = new FileReader()
      reader.onload = e => {}
      const fd = new FormData()
      fd.append('application_id', applicationID)
      fd.append('resume_file', this.resume_file)
      fd.append('email', email)
      delete this.$http.defaults.headers.common.Authorization
      this.$toast({
        component: this.$toastContent,
        props: {
          title: 'Resume Upload',
          icon: 'AlertCircleIcon',
          text: 'Your resume file is uploading in the background',
          variant: 'warning',
        },
      })
      this.$http
        .post('/api/attach-resume', fd)
        .then(response => {
          const result = response.data
          if (result.success) {
            this.successfulApplication(
              response,
              job_id,
              application_url,
              user_id,
            )
          } else {
            this.failedApplication(response, item.application_url)
          }
        })
        .catch(error => {})
    },
    successfulApplication(response, job_id, application_url, user_id) {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Registration Alert',
            icon: 'CheckCircleIcon',
            text: response.data.message,
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )

      //   window.location.reload()
      if (this.$route.name === 'page-event') {
        if (this.registered && this.registered.length > 0) {
          try {
            this.registered.push(user_id)
          } catch (error) {
          }
        } else {
          this.registered = [user_id]
        }

        this.$emit('application-update', this.registered)
        this.getEvent(this.event_id)
      } else {
        if (this.registered) {
          try {
            this.registered[job_id].push(user_id)
          } catch (error) {}
        } else {
          this.registered = []
          this.registered[job_id] = [user_id]
        }
        this.$emit('update-registration', this.registered)
      }

      this.applied = true
      // this.book.text = "Registered";
      // this.book.color = "success";

      this.popupReg = false
      if (application_url) {
        // window.location.href = application_url
        window.open(application_url, '_blank').focus()
      }

      this.showEventButtons = false
      setTimeout(() => {
        this.showEventButtons = true
      }, 200)
    },
    failedApplication(response, application_url) {
      if (application_url) {
        // window.location.href = application_url
        window.open(application_url, '_blank').focus()
      }
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Registration Alert Failed',
            icon: 'AlertCircleIcon',
            text: response.data.message,
            variant: 'danger',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    confirmApplication(args = null) {
      this.popup_quiz = false

      const currentUser = this.$store.state.auth.ActiveUser

      const user_id = currentUser.id

      const item = this.popup_event

      const all_data = {
        id: this.applicationId,
        job_id: item.job_id,
        user_id,
        interviewed: false,
        save_nric: this.save_nric,
        questions_answers: this.popup_questions,
        resume_file: this.resume_file ? this.resume_file : currentUser.resume_file,
      }

      this.popup_quiz = false
      if (!user_id) {
        this.event_id = item.job_id
        this.popupReg = true
      } else {
        this.$http
          .post('/api/book', all_data)
          .then(response => {
            const { success, application_id } = response.data
            if (success) {
              this.successfulApplication(
                response,
                item.job_id,
                item.application_url,
                user_id,
              )
              //   this.$router.go()
            } else {
              this.failedApplication(response, item.application_url)
            }
          })
          .catch(error => {
            this.$handleErrorResponse(error)
          })
      }

      this.resetApplicationForm()
    },
    cancelApplication() {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Job Application Update',
            icon: 'AlertCircleIcon',
            text: 'Your event application has been cancelled.',
            variant: 'warning',
          },
        },
        { timeout: this.$longestTimeout },
      )
      this.resetApplicationForm()
    },
    afterUserLoggedIn() {
      if (this.popupReg === true) {
        this.popupReg = false
      }

      this.getUser(true)
    },
    joinEvent(item, event, bypass = false) {
      const { id } = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser
        : { id: null }
      // event.srcElement.innerText = 'Loading...'
      const allApplicationIDs = item.job_applications ? item.job_applications.map(application => application.applicant_id) : []

      if (!allApplicationIDs.includes(id)) {
        this.eventId = item.id
        localStorage.setItem('applied_job', JSON.stringify(item))
        if (!isUserLoggedIn()) {
          this.popupReg = true
        } else {
          // eslint-disable-next-line no-lonely-if
          if (this.checkProfileCompletion()) {
            // incomplete = true, show profile question popup instead
            this.afterUserLoggedIn()
          } else {
            if (item.application_url) {
              localStorage.setItem('application_url', item.application_url)
            }
            localStorage.setItem('current_event', item.id)
            this.popup_event = item

            if ((item.job_questions.length > 0 || item.event_question.length > 0) && !bypass) {
              const all_questions = []
              item.event_questions.forEach(question => {
                if (!this.$isEmpty(question.question)) {
                  if (!all_questions.includes(question.question)) {
                    all_questions.push(question.question)
                  }
                }
              })
              item.job_questions.forEach(question => {
                if (!this.$isEmpty(question.question)) {
                  if (!all_questions.includes(question.question)) {
                    all_questions.push(question.question)
                  }
                }
              })

              this.popup_questions = []

              const { nric_no } = this.$store.state.auth.ActiveUser
                ? this.$store.state.auth.ActiveUser
                : { nric_no: false }

              all_questions.map(quiz => {
                if (nric_no && quiz.toLowerCase().includes('nric')) {
                  // this.popup_questions.push({ question: quiz, answer: nric_no })
                  this.save_nric = false
                } else {
                  this.popup_questions.push({ question: quiz, answer: '' })
                }
              })

              if (this.popup_questions.length > 0) {
                this.popup_quiz = false
                setTimeout(() => {
                  this.popup_quiz = true
                }, 200)

                this.$emit('open-job-form', {
                  questions: this.popup_questions,
                  save_nric: this.save_nric,
                })
              } else {
                this.$bvModal
                  .msgBoxConfirm(
                    `You are about to apply for ${item.position} event. Do you want to proceed?`,
                    {
                      title: 'Confirm Job Application',
                      okVariant: 'warning',
                      okTitle: 'Yes',
                      cancelTitle: 'No',
                      cancelVariant: 'outline-secondary',
                      hideHeaderClose: false,
                      centered: true,
                    },
                  )
                  .then(value => {
                    if (value) {
                      this.confirmApplication()

                      // ! temporary work around, to solve the green button Apply now button not changing to Applyied
                      // if (item.refresh_page) {
                      //   this.$router.go()
                      // }
                    } else {
                      this.cancelApplication()
                    }
                  })
              }
            } else {
              if (this.resume_choice === 'new-resume') {
                if (this.$isEmpty(this.resume_file)) {
                  this.$toastDanger('Incomplete Form', 'Please upload your resume.')
                  return
                }
              }
              this.$bvModal
                .msgBoxConfirm(
                  `You are about to apply for ${item.position} event. Do you want to proceed?`,
                  {
                    title: 'Confirm Job Application',
                    okVariant: 'warning',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                  },
                )
                .then(value => {
                  if (value) {
                    this.confirmApplication()

                    // ! temporary work around, to solve the green button Apply now button not changing to Applyied
                    //   if (item.refresh_page) {
                    //     this.$router.go()
                    //   }
                  } else {
                    this.cancelApplication()
                  }
                })
            }
          }
        }
      } else {
        this.$toast({
          component: this.$toastContent,
          props: {
            title: 'Job Application Update',
            icon: 'AlertCircleIcon',
            text: 'You have already applied for this job.',
            variant: 'warning',
          },
        })
      }
    },
    resetApplicationForm() {
      this.applicationId = null // reset, so when applying for the next one doesn't cause error
      this.resume_file = null
      this.resume_choice = 'existing-resume'
    },
    getUser(isLogin = false, isRegister = false) {
      const { token } = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser
        : { token: null }
      if (token) {
        this.$http.defaults.headers.common.Authorization = `Token ${token}`
        const url = isRegister || isLogin ? '/api/user?bypass_session=yes' : '/api/user'
        this.$http
          .get(url)
          .then(response => {
            if (response.data.errors) {
              if (
                response.data.errors[0] === 'User not found'
                || response.data.errors[0] === 'Could not validate credentials'
              ) {
                localStorage.removeItem('userInfo')
                localStorage.removeItem('accessToken')
              }
            } else {
              this.$store.commit('auth/UPDATE_USER_INFO', response.data)

              this.current_event = localStorage.getItem('current_event')
                ? localStorage.getItem('current_event')
                : ''

              const { account_type } = this.$store.state.auth.ActiveUser
              if (account_type === this.$AccountTypes.APPLICANT) {
                this.normal = true
              } else {
                this.normal = false
              }
              const event = JSON.parse(localStorage.getItem('applied_job'))
              this.$emit('login-state', true)
              if (isLogin && account_type === this.$AccountTypes.APPLICANT) {
                if (this.checkProfileCompletion()) {
                  // if incomplete = true, prepare question to ask
                  this.prepareProfileQuestions()
                } else {
                  // ! temporary work around, to solve the green button Apply now button not changing to Applyied
                  this.popupProfileQuestions = false

                  this.joinEvent(event, null)
                }
              }
              if (isRegister) {
                this.prepareProfileQuestions()
              }
              if (event && Object.keys(event).length > 0) {
                // this.popupProfileQuestions = false
                // this.joinEvent(event, null)
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },

    afterUserRegistered() {
      if (this.popupReg === true) {
        this.popupReg = false
      }
      this.getUser(false, true)
    },
    async prepareProfileQuestions() {
      await this.getEmployments()
      await this.getEducations()

      this.profileQuestionList = []
      this.user = this.$store.state.auth.ActiveUser
      if (!this.user.resume_file && this.normal === true) {
        this.profileQuestionList.push('upload_resume')
      } else {
        this.viewUploadedResume()
      }

      if (!this.user.gender || this.user.gender === 'Not Set') {
        this.profileQuestionList.push('gender')
      }

      if (
        !this.user.employment_status
        || this.user.employment_status === 'Not Set'
      ) {
        this.profileQuestionList.push('employment_status')
      }
      if (!this.user.citizenship || this.user.citizenship === 'Not Set') {
        this.profileQuestionList.push('citizenship')
      }

      // else if (this.user.citizenship.toLowerCase() === 'others' && this.$isEmpty(this.user.citizenship_others)) {
      //   this.profileQuestionList.push('citizenship')
      // }

      if (!this.user.preferred_location || this.user.preferred_location.length <= 0) {
        this.profileQuestionList.push('preferred_location')
      }

      if (!this.user.preferred_industry || this.user.preferred_industry.length <= 0) {
        this.profileQuestionList.push('preferred_industry')
      }

      if (
        this.$isEmpty(this.user.preferred_position)
      ) {
        this.profileQuestionList.push('preferred_position')
      }

      if (!this.user.postal_code || this.user.postal_code === 'Not Set') {
        this.profileQuestionList.push('postal_code')
      }

      if (!this.user.date_of_birth || this.user.date_of_birth === 'Not Set') {
        this.profileQuestionList.push('date_of_birth')
      }

      if (!this.user.highest_qualification || this.user.highest_qualification === 'Not Set') {
        this.profileQuestionList.push('highest_qualification')
      }

      if (
        !this.user.availability_number || !this.user.availability_period
        || this.user.availability_number === 'Not Set'
      ) {
        this.profileQuestionList.push('availability')
      }

      if (this.employmentInfo && this.employmentInfo.length <= 0 || !this.employmentInfo) {
        this.profileQuestionList.push('employments')
      }

      if (this.educationInfo && this.educationInfo.length <= 0 || !this.educationInfo) {
        this.profileQuestionList.push('educations')
      }

      if (this.profileQuestionList.length > 0) {
        // not complete, show popup, 1. upload_resume
        this.profileQuestionList.push('submit') // last page
        this.currentProfileQuestion = this.profileQuestionList[0] // show the first question

        this.$emit('open-completion-form', true)
        this.popupProfileQuestions = true
      } else {
        // completed
        this.popupProfileQuestions = false
      }
    },

    async viewUploadedResume() {
      const fileUrl = await this.$generateDownloadUrl(this.user.resume_file)
      if (this.user.resume_file && this.user.resume_file.endsWith('pdf')) {
        this.document_type = 'pdf'
        this.file_source = fileUrl
      } else {
        this.document_type = 'docs'
        this.file_source = `https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`
      }
    },

    // employments and educations
    async getEmployments() {
      await this.$http
        .get('/api/employment-data')
        .then(response => {
          this.employmentInfo = response.data.message
          this.isLoading = false
          // this.$forceUpdate();
        })
        .catch(error => {})
    },
    async getEducations() {
      await this.$http
        .get('/api/education-data')
        .then(response => {
          this.educationInfo = response.data.message
          this.isLoading = false
          // this.$forceUpdate();
        })
        .catch(error => {
          console.log(error)
        })
    },
    checkIfAnswered() {
      for (const quiz of this.popup_questions) {
        if (quiz.answer === '') {
          return false
        }
        if (
          quiz.question.toLowerCase().includes('nric')
          && !this.validateNRIC(quiz.answer)
        ) {
          return false
        }

        if (this.resume_choice === 'new-resume') {
          if (this.$isEmpty(this.resume_file)) {
            return false
          }
        }
      }
      return true
    },
    checkIfE2iLoaAccepted() {
      if (this.popup_event && Object.keys(this.popup_event)) {
        if (
          this.$isEmpty(this.popup_event.event_id)
            ? this.$isE2i(this.popup_event.company_name)
            : this.$isE2i(this.popup_event.organiser_name)
        ) {
          return this.isE2iLoaAccepted
        }
      }

      return false
    },
    checkIfLoaAccepted() {
      let accepted = false
      if (this.popup_event && Object.keys(this.popup_event)) {
        if (
          this.$isEmpty(this.popup_event.event_id)
            ? this.$isE2i(this.popup_event.company_name)
            : this.$isE2i(this.popup_event.organiser_name)
        ) {
          accepted = this.isE2iLoaAccepted
        } else {
          accepted = this.isCompanyLoaAccepted
        }
      }

      return accepted
    },

    validateNRIC(nricNo) {
      const regex = new RegExp(/^[STFGM]\d{7}[A-Z]$/)
      const result = regex.test(nricNo)

      return result
    },
    setApplicationId(id) {
      this.applicationId = id
    },
  },
}
